import { apiMethods } from '../../infrastructure/api';
import { readCountries, saveCountries } from '../../infrastructure/store';

export const useGetCountries = () => {
	const getCountries = apiMethods.useGetCountries();

	return async () => {
		const maybeCountries = readCountries();
		if (maybeCountries) {
			return;
		}

		const result = await getCountries();
		if (result.isRight()) {
			saveCountries(result.value);
		}
	};
};
