import type { Countries } from '../../types';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';

type ApiData = Countries;

type ApiResponse = Response<ApiData>;

export function toDomain(res: ApiResponse): Countries {
	return res.result;
}

export const useGetCountries = () => {
	const api = useApi();

	return async () => {
		return (await api<ApiResponse>('/Locations/getCountries/')).mapRight(
			toDomain,
		);
	};
};
