import type { Cities } from '../../types';
import { useApi } from '@/src/services/network';
import type { ApiTypes } from '~/src/shared/lib';

type ApiData = Cities;

type ApiResponse = ApiTypes.Response<ApiData>;

export function toDomain(res: ApiResponse): Cities {
	return res.result;
}

export const useGetCities = () => {
	const api = useApi();

	return async (countryCode, query) => {
		return (await api<ApiResponse>('/Locations/findCity/', {
			method: 'POST',
			body: {
				country_code: countryCode,
				query: query,
			},
		})).mapRight(
			toDomain,
		);
	};
};
